.file-upload-container {
  display: flex; /* Use flexbox to align items in a row */
  justify-content: space-between; /* Space between buttons */
  align-items: center; /* Center items vertically */
  margin-top: 20px; /* Add some margin if needed */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */

  // Styles for the file upload and camera upload sections
  .file-upload,
  .camera-upload {
    flex: 1; /* Allow buttons to grow equally */
    display: flex; /* Use flexbox for button content */
    justify-content: center; /* Center button content */
    align-items: center; /* Center button content vertically */
    margin: 10px; /* Add margin around buttons for spacing */

    // Adjust button sizes for smaller screens
    button {
      padding: 10px 15px; /* Adjust padding for buttons */
      font-size: 1rem; /* Base font size */
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .file-upload-container {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
    align-items: stretch; /* Stretch buttons to full width */
    
    .file-upload,
    .camera-upload {
      width: 100%; /* Full width for buttons */
      margin: 5px 0; /* Vertical margin for spacing */
    }
  }
}

@media (max-width: 480px) {
  .file-upload-container {
    .file-upload,
    .camera-upload {
      button {
        padding: 8px 12px; /* Smaller padding for mobile */
        font-size: 0.9rem; /* Slightly smaller font size */
      }
    }
  }
}

.upload-button, .camera-button {
  display: flex; /* Use flexbox for button content */
  align-items: center; /* Center icon and text vertically */
  padding: 12px 24px; /* Add padding for a better look */
  margin: 0 10px; /* Add margin between buttons */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
  font-size: 1rem; /* Set a base font size */
  font-weight: 500; /* Medium font weight for better readability */
  color: #333; /* Dark text color for contrast */
  background-color: #ffffff; /* Default background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.upload-button:hover, .camera-button:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.upload-button svg, .camera-button svg {
  margin-right: 8px; /* Space between icon and text */
  fill: #007bff; /* Icon color */
  width: 20px; /* Set icon width */
  height: 20px; /* Set icon height */
}

span {
  font-size: 1rem; /* Ensure text size is consistent */
  line-height: 1.5; /* Improve line height for readability */
}

.image-preview {
  img {
    width: 210px;
    padding: 10px;
    border: 1px dashed #1685e2;
  }
}