.previous-button, .next-button {
    align-items: center;
    background-color: #fff;
    border: 2px solid #4CAF50;
    border-radius: 4px;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    fill: #4CAF50;
    // font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    justify-content: center;
    /* letter-spacing: -.8px; */
    line-height: 24px;
    min-width: 140px;
    outline: 0;
    padding: 0 17px;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    
    &:hover {
        color: #000;
        background-color: inherit;
        border: 2px solid #4CAF50;
    }
}

.next-button {
    border-color: #0d6efd;
    fill: #0d6efd;
    &:hover {
        color: #000;
        background-color: inherit;
        border: 2px solid #0d6efd;
    }
}