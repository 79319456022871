.feedback-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.feedback-icon {
  width: 50px;
  height: 50px;
  background-color: #C30101;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    background-color: #940000;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: white;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: rotate(15deg);
  }
}

.feedback-popover {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 300px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
  pointer-events: none;

  &.not-fixed {
    bottom: 94px;
    right: 24px;
  }

  &.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }

  h2 {
    margin-top: 0;
    color: #333;
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 20px;

    input, textarea {
      width: -webkit-fill-available !important;
      padding: 12px !important;
      border: 1px solid #e0e0e0 !important;
      border-radius: 8px !important;
      font-size: 14px !important;
      transition: border-color 0.3s ease !important;

      &:focus {
        outline: none !important;
        border-color: #4CAF50 !important;
      }
    }

    textarea {
      height: 120px !important;
      resize: vertical !important;
    }
  }

  .error {
    color: #f44336;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .button-group {
    display: flex;
    justify-content: center;

    button {
      padding: 12px 24px;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      background-color: #4CAF50;
      color: white;
      font-size: 16px;
      transition: all 0.3s ease;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #45a049;
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      &:disabled {
        background-color: #a5d6a7;
        cursor: not-allowed;
        transform: none;
        box-shadow: none;
      }
    }
  }
}

.screenshot-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: none;

  .capture-screenshot {
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    margin: 0;
    padding: 0;

    &:hover {
        background-color: transparent;
        margin: 0;
        padding: 0;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: #333; // Adjust this color to match your design
    }
  }

  .screenshot-actions {
    display: flex;
    align-items: center;
    gap: 10px;

    .screenshot-captured {
      color: #4CAF50;
      font-size: 14px;
    }

    .download-screenshot {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
