.food-purchase-decision-maker {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  
  @media (max-width: 840px) {
    padding: 10px;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }

  form.health-form, .saved-data, .health-form-container {
    // background-color: #f9f9f9;
    // padding: 20px;
    // border-radius: 8px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // margin-bottom: 20px;
    .card {
      border: 1px solid #007bff; // Optional: Add a border color
      border-radius: 8px;
    }
  }

  .saved-data {
    p {
        margin-bottom: 5px;
    }

    .profile-header {
      // display: flex;
      // justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      p {
        margin: 0;
      }
    }

    .edit-icon {
      border: none;
      cursor: pointer;
      // padding: 5px;
      // transition: transform 0.2s ease-in-out;
      // width: 100px;
      // margin-left: 15px;

      // &:hover {
      //   transform: scale(1.1);
      // }

      // svg {
      //   fill: #4CAF50;
      // }
    }
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      text-align: left;
    }

    input[type="text"],
    input[type="number"],
    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
    }

    input[type="checkbox"] {
      margin-right: 10px;
    }

    // Style for checkbox labels
    div {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      label {
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }

  .submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;

    &:hover {
      background-color: #45a049;
    }

    &.sing-in-button {
      padding: 12px 24px;
      margin: 0 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      width: 300px;
      margin: auto;
      margin-top: 20px;
    }
  }

  .api-response {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;

    &.yes {
      background-color: #d4edda;
      border: 1px solid #28a745;
    }

    &.no {
      background-color: #f8d7da;
      border: 1px solid #dc3545;
    }

    .decision {
      font-size: 24px;
      text-align: center;
      margin-bottom: 15px;
      color: #333;
    }

    .score-container {
      background-color: #e9ecef;
      border-radius: 4px;
      height: 20px;
      margin-bottom: 15px;
      position: relative;
    }

    .score-bar {
      background-color: #007bff;
      height: 100%;
      border-radius: 4px;
      transition: width 0.5s ease-out;
    }

    .score-text {
      position: absolute;
      right: 5px;
      top: 0;
      color: #333;
      font-weight: bold;
    }

    .reason {
      font-size: 16px;
      line-height: 1.5;
      color: #333;
    }
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }

  .image-upload-options {
    margin-top: 20px;

    .file-upload,
    .camera-upload {
      margin: 10px 0;

      .upload-button,
      .camera-button {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 100%;
        width: auto;
        min-width: 200px;
        padding: 10px;
        color: white;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;

        svg {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          fill: white;
          margin-right: 10px;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media (max-width: 480px) {
          flex-direction: column;
          
          svg {
            margin-right: 0;
            margin-bottom: 5px;
          }

          span {
            font-size: 14px;
            text-align: center;
          }
        }
      }

      .upload-button {
        background-color: #198754;

        &:hover {
          background-color: #198754;
        }
      }

      .camera-button {
        background-color: #212529;

        &:hover {
          background-color: #212529;
        }
      }
    }

    .upload-hint {
      display: flex;
      align-items: center;
      background-color: #e8f5e9;
      border: 1px solid #c8e6c9;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      &:hover {
        background-color: #c8e6c9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        fill: #4CAF50;
      }

      span {
        font-size: 14px;
        color: #2E7D32;
        line-height: 1.4;
      }
    }
  }

  .camera-container {
    width: 100%;
    max-width: 640px;
    margin: 20px auto;
    // background-color: #000;
    border-radius: 8px;
    overflow: hidden;

    video {
      width: 100%;
      height: auto;
      display: block;
    }

    .camera-controls {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #f0f0f0;

      button {
        flex: 1;
        margin: 0 5px;
        padding: 10px;
        background-color: #4CAF50;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #45a049;
        }

        &:last-child {
          background-color: #f44336;

          &:hover {
            background-color: #d32f2f;
          }
        }
      }
    }
  }

  .logo {
    text-align: center;
    svg {
      max-width: 100%;
      height: auto;
    }
  }

  .faqs {
    margin-top: 100px;

    h2 {
      color: #000;
      margin-bottom: 20px;
      text-align: center;
      font-size: 24px;
    }

    .faq-item {
      margin-bottom: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      overflow: hidden;

      &.open {
        .faq-answer {
          max-height: 1000px;
          transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
        }

        .faq-icon {
          transform: rotate(180deg);
        }
      }
    }

    .faq-question {
      width: 100%;
      text-align: left;
      padding: 15px 20px;
      background-color: #ffffff;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.3s ease;
      margin: 0;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .faq-icon {
      font-size: 24px;
      transition: transform 0.3s ease;
    }

    .faq-answer {
      max-height: 0;
      padding: 0 20px;
      background-color: #ffffff;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;

      p {
        color: #666;
        line-height: 1.6;
        margin: 15px 0;
      }
    }
  }

  .loading {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #007bff;
  }

  .error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .header {
    text-align: center;
    margin-bottom: 60px;

    .title {
      font-size: 6rem;
      color: #2c3e50;
      margin-bottom: 20px;
      animation: fadeInDown 0.8s ease-out;
      font-weight: 900;
      
      background: #007BFF;
      background: linear-gradient(to right, #007BFF 0%, #4CAF50 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 1023px) {
        font-size: 2.5rem;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    .subtitle {
      font-size: 2rem;
      color: #34495e;
      // max-width: 700px;
      margin: 0 auto;
      line-height: 1.6;
      animation: fadeInUp 0.8s ease-out 0.2s both;
      font-weight: 500;

      span {
        font-size: 2rem;
        display: block;
        @media (max-width: 768px) {
          font-size: 1rem;
          display: inline;
        }
      }

      @media (max-width: 768px) {
        font-size: 1rem;
      }

      .highlight {
        color: #4CAF50;
        font-weight: bold;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #4CAF50;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }

        &:hover::after {
          transform: scaleX(1);
        }
      }
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .feedback-footer {
    margin-top: 40px;
    background: linear-gradient(135deg, #4CAF50, #45a049);
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    .feedback-content {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 600px;
      margin: 0 auto;
    }

    .feedback-icon {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      fill: white;
    }

    p {
      font-size: 16px;
      margin: 0;
    }

    a {
      color: #ffffff;
      text-decoration: none;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px 5px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.2);
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        text-decoration: underline;
      }
    }
  }

  .height-group {
    .height-inputs {
      display: flex;
      gap: 15px;

      .height-feet,
      .height-inches {
        flex: 1;
        display: flex;
        align-items: center;

        label {
          margin-right: 5px;
          font-size: 0.9em;
          color: #666;
        }

        select {
          flex: 1;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
          appearance: none;
          background-color: white;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 8px center;
          padding-right: 24px;

          &:focus {
            outline: none;
            border-color: #007bff;
          }
        }
      }
    }
  }

  .how-to-use {
    margin: 60px 0 100px 0;
    padding: 60px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      padding: 20px 10px;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
      color: #333;
    }

    .steps-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;
    }

    .step {
      flex: 1;
      min-width: 250px;
      max-width: 400px;
      text-align: center;
      padding: 20px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      .step-icon {
        margin-bottom: 15px;
        color: #007bff;
      }

      h3 {
        margin-bottom: 10px;
        color: #333;
      }

      p {
        color: #666;
        font-size: 0.9em;
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 768px) {
    .how-to-use .steps-container {
      flex-direction: column;
      align-items: center;
    }
  }
}

.gradient-bg {
  background: linear-gradient(to right, #f0f7ff, #f0fff4);
}
.step-circle {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.875rem;
}

.header-menu {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between logo and button */
  align-items: center; /* Center items vertically */
  padding: 10px 20px; /* Add padding around the header */
  background-color: #f8f9fa; /* Light background color for the header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }

  .header-button {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .header-button-icon {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
}

.user-count {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin-top: 10px; /* Space above the user count */
  font-size: 1.2rem; /* Increase font size */
  color: #333; /* Darker text color for better readability */
  font-weight: bold; /* Make the text bold */

  .user-icon {
    margin-right: 8px; /* Space between icon and text */
    color: #007bff; /* Change icon color */
    font-size: 1.5rem; /* Increase icon size */
  }

  .user-count-text {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Optional: Add text shadow for depth */
  }
}
