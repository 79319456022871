.account-section {
  .card {
    border: 1px solid #007bff; // Optional: Add a border color
    border-radius: 8px;
  }

  .card-title {
    font-weight: bold;
    color: #007bff; // Optional: Change title color
  }
}

.purchase-button {
  display: flex;
  align-items: center; // Center the icon and text vertically
  font-size: 1.1rem; // Slightly increase font size
  padding: 10px 20px; // Add padding for a larger button
  transition: background-color 0.3s ease, transform 0.2s ease; // Smooth transition for hover effects

  &:hover {
    background-color: #0056b3; // Darker shade on hover
    transform: scale(1.05); // Slightly scale up on hover
  }

  .fa-shopping-cart {
    font-size: 1.2rem; // Increase icon size
  }
}

.product-scan-section {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ccc; /* Optional: Add a border */
  border-radius: 8px; /* Optional: Rounded corners */
  background-color: #f9f9f9; /* Light background color */
}

.suggested-products {
  display: flex; /* Use flexbox to align items */
  justify-content: center; /* Center items */
  flex-wrap: wrap; /* Allow wrapping */
  margin-top: 10px; /* Space above the products */
}

.product-item {
  margin: 10px; /* Space between product items */
  text-align: center; /* Center text */
}

.product-image {
  width: 100px; /* Set a fixed width for images */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Optional: Rounded corners for images */
}

.motivational-text {
  font-weight: bold; /* Make the motivational text bold */
  color: #007bff; /* Change text color */
}
