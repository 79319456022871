.web-to-app {
  // max-width: 600px; /* Limit the width of the content */
  margin: 20px auto; /* Center the content */
  padding: 20px; /* Add padding */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 8px; /* Rounded corners */
  // background-color: #f9f9f9; /* Light background color */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */

  h1 {
    font-size: 24px; /* Heading size */
    color: #333; /* Dark text color */
  }

  p {
    font-size: 16px; /* Paragraph size */
    color: #555; /* Slightly lighter text color */
  }

  ol {
    margin-left: 20px; /* Indent ordered list */
  }

  li {
    margin-bottom: 10px; /* Space between list items */
  }
}
